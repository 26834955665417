import template from './production_schedule.html';

class ProductionSchedulePageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.workstations = ko.observableArray([]);
		this.actual_workstations = ko.observableArray([]);
		this.work_order_events = ko.observableArray([]);
		this.calendars = ko.observableArray([]);

		DomHelper.setTheme('Classic');

		this.loading(false);
	}
}

class ProductionSchedulePageClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProductionSchedulePageViewModel(this);
	}

	async init ()
	{
		document.title = 'Production Schedule'
		this.viewModel.loading(true);

		let [workstations, workstation_events] = await Promise.all([
			this.load_workstations(),
			this.load_workstation_events()
		]);

		let calendars = [];
		let actual_workstations = [];
		let work_order_events = [];
		let workstation_map = {};
		let wo_number_to_id = {};
		let icons = ['fa fa-conveyor-belt', 'fa fa-industry', 'fa fa-building', 'fa fa-trowel-bricks', 'fa fa-arrows-to-dot', 'fa fa-object-ungroup', 'fa fa-car-battery', 'fa fa-bore-hole', 'fa fa-sliders', 'fa fa-flask'];
	
		// Create resources and calendars from all workstations
		workstations.forEach(record => {
			let random_index = Math.floor(Math.random() * icons.length);
			let selected_icon = icons[random_index];
			let schedule = record.schedule;
			let intervals = [];

			if (schedule && schedule.length > 0 && schedule[0] != null)
			{
				let dow = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
				let days = {
					'monday': 'Mon',
					'tuesday': 'Tue',
					'wednesday': 'Wed',
					'thursday': 'Thu',
					'friday': 'Fri',
					'saturday': 'Sat',
					'sunday': 'Sun'
				};

				schedule.forEach(schedule_obj => {
					for (let day of dow)
					{
						let day_schedule = schedule_obj[day];
						let start_time = day_schedule ? day_schedule.start_time : null;
						let end_time = day_schedule ? day_schedule.end_time : null;

						if (start_time && end_time)
						{
							let day_abbrev = days[day];
		
							intervals.push({
								recurrentStartDate: `on ${day_abbrev} at ${start_time}`,
								recurrentEndDate: `on ${day_abbrev} at ${end_time}`,
								isWorking: schedule_obj.working
							});
						}
						// If only start or end time is missing, consider it as non-working
					}
				});
			}

			// Create a calendar for this workstation
			let calendar = {
				id: `calendar_${record.location_id}`,
				name: `Calendar for ${record.name}`,
				unspecifiedTimeIsWorking: false,
				intervals: intervals
			};
			calendars.push(calendar);

			let resource = {
				id: record.location_id,
				name: record.name,
				role: 'Workstations',
				calendar: calendar.id,
				iconCls: selected_icon,
				image: false,
				setup_time: record.setup_time,
				schedule: record.schedule
			};

			actual_workstations.push(resource);
			workstation_map[record.location_id] = record.location_id;
		});

		// Create events from workstation_events
		workstation_events.forEach(record => {
			let workstation_id = record.workstation_location_id;
			if (workstation_id)
			{
				let end_date = new Date(record.event_end_date);
				let duration_minutes = (this.duration_to_minutes(record.event_duration));
				let setup_time = record.setup_time ? this.duration_to_minutes(record.setup_time) : 0;
				let cleanup_time = record.cleanup_time ? this.duration_to_minutes(record.cleanup_time) : 0;

				let event = {
					id: `WO_ID${record.work_order_id}`,
					resourceId: workstation_id,
					name: record.work_order_number,
					duration: duration_minutes,
					durationUnit: "minute",
					endDate: end_date,
					preamble: setup_time > 0 ? `${setup_time} minute` : null,
					postamble: cleanup_time > 0 ? `${cleanup_time} minute` : null,
					note: record.work_order_notes
				};
	
				work_order_events.push(event);
			}

			wo_number_to_id[record.work_order_number] = `WO_ID${record.work_order_id}`;

			if (record.event_dependent_on && record.event_dependent_on.length > 0)
			{
				record.event_dependent_on.forEach(dep_wo_number => {
					let from_event_id = wo_number_to_id[dep_wo_number];
					let to_event_id = `WO_ID${record.work_order_id}`;
	
					if (from_event_id && to_event_id)
					{
						work_order_events.push({
							fromEvent: from_event_id,
							toEvent: to_event_id
						});
					}
				});
			}
		});

		this.viewModel.actual_workstations(actual_workstations);
		this.viewModel.work_order_events(work_order_events);
		this.viewModel.calendars(calendars);

		await this.init_scheduler();

		this.viewModel.loading(false);
	}

	async init_scheduler ()
	{
		const scheduler = new SchedulerPro({
			appendTo: 'scs_production_schedule',
			eventStyle: 'rounded',
			viewPreset: {
				base: 'hourAndDay',
				tickWidth: 35,
				headers: [
					{
						unit: 'day',
						dateFormat: 'ddd DD/MM' //Mon 01/10
					},
					{
						unit: 'hour',
						dateFormat: 'H'
					}
				]
			},
			// Project Config
			project: {
				autoLoad: true,
				resources: this.viewModel.actual_workstations(),
				events: this.viewModel.work_order_events().filter(event => event.resourceId),
				calendars: this.viewModel.calendars(),
				dependencies: this.viewModel.work_order_events().filter(event => event.fromEvent && event.toEvent),
				stm: {
					autoRecord: true
				}
			},
			// TabBar for buttons and stuff
			tbar: [
				{
					ref: 'fullscreenButton',
					icon: 'fa-solid fa-expand',
					tooltip: 'Toggle Fullscreen',
					class: 'ps-btn-primary',
					onAction: () => {
						const schedulerContainer = document.getElementById('scs_production_schedule');
						
						if (!document.fullscreenElement)
							schedulerContainer.requestFullscreen();
						else
							document.exitFullscreen();
					}
				},
				{
					ref: 'zoomInButton',
					icon: 'fa-solid fa-magnifying-glass-plus',
					class: 'ps-btn-primary',
					onAction: () => scheduler.zoomIn()
				},
				{
					ref: 'zoomOutButton',
					icon: 'fa-solid fa-magnifying-glass-minus',
					class: 'ps-btn-primary',
					onAction: () => scheduler.zoomOut()
				},
				'->',
				{
					type: 'undoredo',
					items: {
						transactionsCombo: null
					}
				},
				{
					ref: 'resetButton',
					text: 'Reset',
					class: 'ps-btn-primary',
					onAction: () => {
						scheduler.project.stm.undoAll();
						scheduler.project.stm.resetQueue();
				
						Toast.show('All changes have been reset.');
					}
				},
				{
					ref: 'saveButton',
					text: 'Save',
					class: 'ps-btn-primary',
					onAction: async () => {
						const changes = scheduler.project.changes;
						console.log('Saving changes: ', changes);

						if (changes === null ) 
						{
							Toast.show('No changes to save.');
							return;
						}

						await this.process_scheduler_changes(changes);
						await scheduler.project.commitAsync();
						scheduler.project.stm.resetQueue();

						Toast.show('All changes have been saved.');
					}
				}
			],
			// Grouping and Features Configuration
			features: {
				dependencies: {
					radius: 5,
					clickWidth: 10
				},
				group: 'role',
				sort: 'name',
				timeRanges: {
					narrowThreshold: 10,
					enableResizing: false,
					tooltipTemplate: ({ timeRange }) => {
						return `${DateHelper.format(timeRange.startDate, 'HH:mm')} ${timeRange.duration ? DateHelper.format(timeRange.endDate, '- HH:mm') : ''} ${timeRange.name}`;
					}
				},
				resourceNonWorkingTime: true,
				cellEdit: true,
				filter: true,
				regionResize: true,
				dependencyEdit: true,
				percentBar: true,
				eventTooltip: {
					header: {
						title: 'Information',
						titleAlign: 'start'
					},
					tools: [
					{
						cls: 'fa fa-trash',
						handler: function() {
							this.eventRecord.remove();
							this.hide();
						}
					},
					{
						cls: 'fa fa-edit',
						handler: function() {
							scheduler.editEvent(this.eventRecord);
						}
					}
					]
				},
				eventBuffer: {
					renderer({ eventRecord, preambleConfig, postambleConfig }) {
						if (eventRecord.preamble)
						{
							preambleConfig.icon = 'fa fa-tools';
							preambleConfig.cls  = 'setup-buffer';
							preambleConfig.text = eventRecord.preamble;
						}
			
						if (eventRecord.postamble)
						{
							postambleConfig.icon = 'fa fa-broom';
							postambleConfig.cls  = 'cleanup-buffer';
							postambleConfig.text = eventRecord.postamble;
						}
					}
				}
			},
			// Columns Configuration
			columns: [
			{
				type: 'resourceInfo',
				text: 'Name',
				showEventCount: true,
				width: 220,
				validNames: null
			},
			{
				type: 'resourceCalendar',
				text: 'Shift / State',
				width: 120
			},
			{
				type: 'action',
				text: 'Actions',
				width: 80,
				align: 'center',
				actions: [
				{
					cls: 'fa fa-fw fa-plus',
					tooltip: 'Add Task',
					onClick: async ({ record }) => {
						// Add a new event linked to the current resource
						const [eventRecord] = scheduler.eventStore.add({
							name: 'New Task',
							startDate: scheduler.startDate,
							duration: 4,
							durationUnit: 'h',
							resourceId: record.id
						});
		
						await scheduler.project.commitAsync();
		
						scheduler.editEvent(eventRecord);
					}
				},
				{
					cls: 'fa fa-fw fa-cog',
					tooltip: 'Workstation Settings',
					onClick: ({ record }) => { Grape.navigate(`workstation/setup/${record.id}`); }
				}
				]
			}
			],
			// Event Styling and Listeners
			eventColor: 'indigo',
			listeners: {
				eventClick({ eventRecord }) {
					let order_id = eventRecord.id.replace('WO_ID', '');
					Grape.navigate(`work/order/edit/${order_id}`);
				}
			}
		});

		await scheduler.project.commitAsync();
		await scheduler.project.stm.enable();

		this.viewModel.loading(false);
	}

	async load_workstations ()
	{
		let workstations = [];
		try {
			let options = {
				table: 'v_workstation',
				schema: 'stock',
				sortorder: 'ASC',
				filter: []
			}

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				this.viewModel.workstations(result.records);

				workstations = result.records.map(record => ({
					location_id: record.location_id,
					name: record.name,
					schedule: record.schedule
				}));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			return workstations;
		}
	}

	async load_workstation_events ()
	{
		let workstation_events = [];
	
		try {
			let options = {
				table: 'v_workstation_events_data',
				schema: 'stock'
			}

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				workstation_events = result.records.map(record => ({
					workstation_location_id: record.workstation_location_id,
					setup_time: (record.work_order_preamble && record.work_order_preamble !== '00:00:00' && record.work_order_preamble !== '0')
						? record.work_order_preamble
						: record.workstation_default_preamble,
					cleanup_time: (record.work_order_postamble && record.work_order_postamble !== '00:00:00' && record.work_order_postamble !== '0')
						? record.work_order_postamble
						: record.workstation_default_postamble,
					work_order_id: record.work_order_id,
					work_order_number: record.work_order_number,
					work_order_notes: record.work_order_notes,
					event_end_date: record.event_end_date,
					event_duration: record.event_duration,
					event_dependent_on: record.event_dependent_on
				}));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			return workstation_events;
		}
	}

	duration_to_minutes (duration_str)
	{
		if (!duration_str) return 0;
		let total_minutes = 0;

		let day_match = duration_str.match(/(\d+)\s*day(?:s)?/i);
		if (day_match) total_minutes += parseInt(day_match[1], 10) * 1440;

		let time_match = duration_str.match(/(\d{1,2}):(\d{2}):(\d{2})/);
		if (time_match) total_minutes += parseInt(time_match[1], 10) * 60 + parseInt(time_match[2], 10) + parseInt(time_match[3], 10) / 60;

		return Math.round(total_minutes);
	}

	async process_scheduler_changes (changes)
	{
		// Process updated calendars
		if (changes.calendars && changes.calendars.updated)
		{
			for (let calendar of changes.calendars.updated)
			{
				let workstation_id = this.get_workstation_id_from_calendar_id(calendar.id);
				let schedule_data = this.prepare_schedule_data(calendar);
				await this.update_workstation_schedule(workstation_id, schedule_data);
			}
		}
		
		// Process added calendars
		if (changes.calendars && changes.calendars.added)
		{
			for (let calendar of changes.calendars.added)
			{
				let workstation_id = this.get_workstation_id_from_calendar_id(calendar.id);
				let schedule_data = this.prepare_schedule_data(calendar);
				await this.add_workstation_schedule(workstation_id, schedule_data);
			}
		}
		
		// Process removed calendars
		if (changes.calendars && changes.calendars.removed)
		{
			for (let calendar of changes.calendars.removed)
			{
				let confirm = await Grape.alerts.confirm({ type: 'danger', title: 'Delete Calendar?', message: 'Are you sure you want to delete a calendar? Choose "no" to save everything but the deletion.' });

				if (!confirm)
					return;
				else
				{
					let workstation_id = this.get_workstation_id_from_calendar_id(calendar.id);
					await this.delete_workstation_schedule(workstation_id);
				}
			}
		}

		// Process updated events
		if (changes.events && changes.events.updated)
		{
			for (let event of changes.events.updated)
			{
				let data = {};

				if (event.id)
				{
					let event_id = event.id;
					data.order_id = event_id.replace('WO_ID', '');

					if (event.note)
						data.note = event.note;

					if (event.name)
						data.order_nr = event.name;

					await this.update_work_order(data);
				}
			}
		}

		// Process updated resources
		if (changes.resources && changes.resources.updated)
		{
			for (let resource of changes.resources.updated)
			{
				let data = {};

				if (resource.id)
				{
					data.location_id = resource.id;

					let workstation = this.viewModel.workstations().find(ws => ws.location_id === data.location_id);

					if (resource.name)
						workstation.name = resource.name;

					await this.update_workstation(workstation);
				}
			}
		}
	}

	get_workstation_id_from_calendar_id (calendar_id)
	{
		let parts = calendar_id.split('_');
		return parts[1];
	}

	prepare_schedule_data (calendar)
	{
		let schedule = [];
	
		if (calendar.intervals && calendar.intervals.added)
			for (let interval of calendar.intervals.added)
			{
				let schedule_item = this.parse_interval(interval);
				schedule.push(schedule_item);
			}
	
		return schedule;
	}
	
	parse_interval (interval)
	{
		let days_regex = /on ([\w,]+) at (\d{2}:\d{2})/;
		let start_match = days_regex.exec(interval.recurrentStartDate);
		let end_match = days_regex.exec(interval.recurrentEndDate);

		if (!start_match) return null;
		if (!end_match) return null;
	
		let days = start_match[1].split(',');
		let start_time = start_match[2];
		let end_time = end_match[2];
	
		let schedule_item = {
			description: interval.name || '',
			working: interval.isWorking || true,
		};
	
		days.forEach(day_abbrev => {
			let day_name = this.get_full_days(day_abbrev);
			schedule_item[day_name.toLowerCase()] = {
				start_time: start_time,
				end_time: end_time,
			};
		});
	
		return schedule_item;
	}
	
	get_full_days (abbrev)
	{
		let days = {
			'Sun': 'Sunday',
			'Mon': 'Monday',
			'Tue': 'Tuesday',
			'Wed': 'Wednesday',
			'Thu': 'Thursday',
			'Fri': 'Friday',
			'Sat': 'Saturday',
		};
		return days[abbrev];
	}

	async update_workstation_schedule (workstation_id, schedule_data)
	{
		try {
			let workstation = this.viewModel.workstations().find(ws => ws.location_id === Number(workstation_id));

			workstation.schedule = schedule_data;

			let result = await Grape.fetches.postJSON('/api/stock-management/workstation', workstation);
		
			if (result.status !== 'OK')
				throw new Error(result.message || result.code);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async delete_workstation_schedule (workstation_id)
	{
		try {
			let workstation = this.viewModel.workstations().find(ws => ws.location_id === Number(workstation_id));

			workstation.schedule = [];

			let result = await Grape.fetches.postJSON('/api/stock-management/workstation', workstation);
		
			if (result.status !== 'OK')
				throw new Error(result.message || result.code);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async update_work_order (data)
	{
		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/order', data);

			if (result.status !== 'OK')
				throw new Error(result.message || result.code);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async update_workstation (workstation)
	{
		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/workstation', workstation);
		
			if (result.status !== 'OK')
				throw new Error(result.message || result.code);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]production/schedule',
	page_class: ProductionSchedulePageClass,
	template: template
}