import template from './stock_levels_summary.html';

/**
 *  Page view model
 */
class StockLevelsSummaryViewModel 
{
	constructor(page) 
	{
		this.page = page;

		this.levels_summary = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.stock_items = ko.observableArray([]);
		
		this.date_start = ko.observable(moment().subtract(1, 'months').format('MM/DD/YYYY'));
		this.date_end = ko.observable(new Date().toISOString().slice(0, 10));
		this.selected_locations = ko.observableArray([]);
	}

	btn_download_csv_click ()
	{
		let csvContent = "Description(Code),Opening Balance,IBT-IN,IBT-OUT,Customer Transactions-Sales,Customer Transactions-Returns,Supplier Transactions-Deliveries,Supplier Transactions-Returns,Adjustments,Closing Balance\n";

		for (let row of this.levels_summary()) 
		{
			let newRow = [];

			newRow.push(`${row.description}(${row.code})`);
			newRow.push(row.lvl_start);
			newRow.push(row.IBT.qty_in);
			newRow.push(row.IBT.qty_out > 0 ? `-${row.IBT.qty_out}` : 0);
			newRow.push(row.SO.sales > 0 ? `-${row.SO.sales}` : 0);
			newRow.push(row.SO.returns);
			newRow.push(row.PO.deliveries);
			newRow.push(row.PO.returns > 0 ? `-${row.PO.returns}` : 0);
			newRow.push(row.adjustments);
			newRow.push(row.lvl_end);

			csvContent += newRow.join(",") + "\n";
		}

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement("a");
		link.setAttribute("href", URL.createObjectURL(blob));
		link.setAttribute("download", 'LevelsSummary' + ".csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	cell_click (value, data, type, subtype)
	{
		if (value === undefined || value == '0')
			return;

		let stock_item = this.stock_items().find(stock => stock.stock_item_id === data.stock_item_id);

		let movement_obj = {
			start_date: this.date_start(),
			end_date: this.date_end(),
			from_locations: this.selected_locations().map(loc => loc.location_id),
			to_locations: this.selected_locations().map(loc => loc.location_id),
			stock_items: stock_item,
			movement_types: type,
			movement_subtypes: subtype
		}

		Grape.navigate(`/stock/movement/list`, { summary_filter: movement_obj });
	}

	reload_click ()
	{
		this.page.updateData();

		const uniqueLocationIds = Array.from(new Set(this.selected_locations().map(loc => loc.location_id)));
		localStorage.setItem('level_summary_last_locations', JSON.stringify(uniqueLocationIds));

		if (this.date_start())
			localStorage.setItem('level_summary_last_start_date', this.date_start());
		if (this.date_end())
			localStorage.setItem('level_summary_last_end_date', this.date_end());
	}
}

/**
 * Page class
 */
class StockLevelsSummaryPage 
{
	constructor(bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new StockLevelsSummaryViewModel(this);
	}

	async init ()
	{
		document.title = 'Stock Levels Summary';

		let locations = await Grape.cache.fetch('ActiveLocations');
		this.viewModel.locations(locations);

		let stock_items = await Grape.cache.fetch('ActiveStockItems');
		this.viewModel.stock_items(stock_items);

		let last_locations = JSON.parse(localStorage.getItem('level_summary_last_locations')) || [];

		for (let last_location of last_locations)
		{
			let selected_locations = locations.find(value => value.location_id == last_location);
			if (selected_locations && !this.viewModel.selected_locations().includes(selected_locations))
				this.viewModel.selected_locations.push(selected_locations);
		}

		let last_de = localStorage.getItem('level_summary_last_end_date');

		if (last_de)
			this.viewModel.date_end(new Date(last_de).toISOString().slice(0, 10));
		else 
		{
			let currentDate = new Date();
			this.viewModel.date_end(currentDate.toISOString().slice(0, 10));
		}

		let last_ds = localStorage.getItem('level_summary_last_start_date');

		if (last_ds)
			this.viewModel.date_start(new Date(last_ds).toISOString().slice(0, 10));
		else 
		{
			let currentDate = new Date();
			currentDate.setMonth(currentDate.getMonth() - 1);
			this.viewModel.date_start(currentDate.toISOString().slice(0, 10));
		}
	}

	async updateData() 
	{
		try 
		{
			if (this.viewModel.selected_locations().length > 0)
			{
				let ds = new Date(this.viewModel.date_start());
				let de = new Date(this.viewModel.date_end());

				if (ds > de)
					throw new Error("Start date must be before end date");
				
				let options = {
					location_ids: this.viewModel.selected_locations().map(loc => loc.location_id) || [],
					start_date: this.viewModel.date_start(),
					end_date: this.viewModel.date_end()
				}
				let result = await Grape.fetches.getJSON('/api/stock-management/stock-level/summary', options);
				
				if (result.status === 'OK')
					this.viewModel.levels_summary(result.records);
				else
					throw new Error(result.message || result.code);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
};

export default {
	route: '/stock/stock_levels/summary',
	page_class: StockLevelsSummaryPage,
	template: template
};