import template from './ko-stock-order-fields.html';

class OrderFieldsComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.order_fields = ko_helper.safe_observableArray([]);
		this.order_fields_input = ko_helper.safe_observableArray(this.params.order_fields || []);
		this.type = ko_helper.safe_observable(this.params.type);
		this.order_type = ko_helper.safe_observable(this.params.order_type);

		this.order_fields_input.subscribe((new_fields) => this.order_fields(new_fields));
		this.order_type.subscribe(() => this.set_order_fields());
	}

	async set_order_fields ()
	{
		
		if (this.type() === 'create' && this.order_type())
		{
			let order_fields = [];
			for (let field of this.order_type().fields)
			{
				order_fields.push({
					fieldName: field.name,
					data: '',
					editing: ko.observable(false)
				});
			};

			this.order_fields(order_fields);
			this.order_fields_input(order_fields);
		}
	}
}

export default {
	name: 'ko-stock-order-fields',
	viewModel: OrderFieldsComponentViewModel,
	module_type: 'ko',
	template: template
}