// COMPONENTS
import checkbox_dropdown from './checkbox-dropdown/checkbox-dropdown.js';
import order_items from './order/order-items/ko-stock-order-items.js';
import order_fields from './order/order-fields/ko-stock-order-fields.js';
import si_search_dropdown from './search-dropdown/si-search-dropdown.js';
import l_search_dropdown from './search-dropdown/l-search-dropdown.js';
import movement_summary from './movement-summary/movement-summary.js';
import movement_transcation from './movement-transactions/movement-transaction.js';
import movements_list from './movements-list/movements-list.js';
import stock_item_dropdown from './stock-items-dropdown/stock-item-dropdown.js';
import locations_treeview from './locations-treeview/locations-treeview.js';
import location_select_treeview from './location-select-treeview/location-select-treeview.js';
import associated_orders from './associated-orders/ko-associated-orders.js';
import schedule_details from './schedule-details/ko-schedule-details.js';
import production_order_items from './production-order-items/ko-production-order-items.js';
import bom_stock_item_dropdown from './bom-stock-item-dropdown/bom-stock-item-dropdown.js';
import stock_graph_filter from './stock-graph-filter/stock-graph-filter.js';
import stock_graph_panel from './stock-graph-panel/stock-graph-panel.js';
import workstation_dropdown from './workstation-dropdown/workstation-dropdown.js';
import workstation_list from './workstation-list/workstation-list.js';
import order_list from './order-list/order-list.js';

export default [
	l_search_dropdown,
	si_search_dropdown,
	stock_item_dropdown,
	movement_transcation,
	movements_list,
	checkbox_dropdown,
	order_items,
	order_fields,
	order_list,
	movement_summary,
	locations_treeview,
	location_select_treeview,
	associated_orders,
	schedule_details,
	production_order_items,
	bom_stock_item_dropdown,
	stock_graph_filter,
	stock_graph_panel,
	workstation_dropdown,
	workstation_list
];
