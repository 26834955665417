import template from './BomItemEdit.html';

class BomItemEditViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.stock_items = ko.observableArray([]);
		this.selected_items = ko.observableArray([]);
		this.components = ko.observableArray([]);
		this.version_name = ko.observable('');
		this.lead_time = ko_helper.safe_observable({
			days: ko.observable(0),
			hours: ko.observable(0),
			minutes: ko.observable(0)
		});

		this.selected_items.subscribe((items) => {
			this.components([])

			for (let item of items)
				this.components.push({
					stock_item_id: item.stock_item_id,
					description: item.description,
					qty: ko.observable(0)
				});
		})
	}

	async btn_save_click ()
	{
		if (this.version_name() === '' || this.version_name() === undefined)
		{
			await Grape.alerts.alert({ type: 'warning', message: 'Please provide a version name', title: 'Missing Version'});
			return;
		}

		if (this.components().length < 1)
		{
			await Grape.alerts.alert({ type: 'warning', message: 'Please select at least one component', title: 'Missing Components'});
			return;
		}

		let { days, hours, minutes } = ko.toJS(this.lead_time());
		const lead_time = `${days} days ${hours} hours ${minutes} minutes`;
		this.dialog.close({
			lead_time: lead_time,
			version_name: this.version_name(),
			components: ko.toJS(this.components())
		});
	}

	btn_close_click ()
	{
		this.dialog.close();
	}
}

class BomItemEditClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new BomItemEditViewModel(this);
		this.name = 'BomItemEditClass';

		this.init();
	}

	async init ()
	{
		let stock_items = await Grape.cache.fetch('ActiveStockItems');

		this.viewModel.stock_items(stock_items);
	}
}

export default {
	name: 'BomItemEdit',
	dialog_class: BomItemEditClass,
	template: template
}