import BomItemEdit from './BomItemEdit/BomItemEdit.js';
import StockItemEdit from './StockItemEdit/StockItemEdit.js';
import MovementNoteEdit from './movement/movement_note_edit.js';
import MovementTransactionCommit from './movement/movement_transaction_commit.js';
import MovementTransactionConfirm from './movement/movement_transaction_confirm.js';
import Prepack from './prepack/prepack.js';
import EditUserLocations from './user_locations/edit_user_locations.js';
import EditLocation from './location/edit_location.js';
import EditSupplier from './location/edit_supplier.js';
import EditBilling from './location/edit_billing_details.js';
import EditContact from './location/edit_contact_details.js';
import PartialDeliveryDialog from './orders/create_partial_delivery.js';
import ViewTransactionDialog from './orders/view_transaction.js';
import EditStockItemLocation from './stock_item_location/edit_stock_item_location.js';
import SalesOrderConfirm from './orders/sales_order_confirm.js';
import OrderConfirm from './orders/order_confirm.js';
import DiscrepancyConfirmationDialog from './orders/confirm_discrepancy.js';
import ViewStockGraphDialog from './view_stock_graph/view_stock_graph.js';
import DeleteWorkstation from "./workstation/DeleteWorkstation.js";
import WorkstationScheduleOverlap from "./workstation/WorkstationScheduleOverlap.js";

export default [
	BomItemEdit,
	EditStockItemLocation,
	SalesOrderConfirm,
	OrderConfirm,
	EditContact,
	EditBilling,
	StockItemEdit,
	MovementTransactionConfirm,
	MovementTransactionCommit,
	MovementNoteEdit, 
	Prepack,
	EditUserLocations,
	EditLocation,
	EditSupplier,
	PartialDeliveryDialog,
	ViewTransactionDialog,
	DiscrepancyConfirmationDialog,
	ViewStockGraphDialog,
	DeleteWorkstation,
	WorkstationScheduleOverlap
];