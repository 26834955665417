import template from './purchase_orders.html';

class PurchaseOrderViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class PurchaseOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new PurchaseOrderViewModel(this);
		this.initialized = false;
	}

}

export default {
	route: '/stock/order/purchase_orders/',
	page_class: PurchaseOrderPage,
	template: template
}
