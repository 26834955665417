import template from './movement_list.html';

class StockMovementViewModel 
{
	constructor(page)
	{
		this.page = page;
		this.locations = ko.observableArray([]);
		this.user_uuid = ko.observable(null);

		//currently selected values
		this.selectedFromLocations = ko.observableArray([]);
		this.selectedToLocations = ko.observableArray([]);
		this.selectedMovementTypes = ko.observableArray([]);
		this.selectedMovementSubtypes = ko.observableArray([]);
		this.selectedStockItems = ko.observableArray([]);
		this.selectedStockItemIDs = ko.observableArray([]);

		//filters
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.movement_types = ko.observableArray([]);
		this.movement_subtypes = ko.observableArray([]);
		this.stock_items = ko.observableArray([]);
		this.summary_filter = ko.observable();
		this.show_suppliers = ko.observable(false);
		
		//for csv document download
		this.currentOptions = ko.observableArray();
		this.download_btn_title = ko.observable('Download Records');

		//filter bindings
		this.current_filter_name = ko.observable('');
		this.current_filter_id = ko.observable();
		this.current_filter_public = ko.observable(false);
		this.current_filter_criteria = ko.observableArray([]);
		this.filters_array = ko.observableArray([]);

		//subscribe functions
		this.start_date.subscribe(() => this.page.loadData() );
		this.end_date.subscribe(() => this.page.loadData() );
		this.selectedStockItems.subscribe(() => this.page.loadData() );
		this.selectedFromLocations.subscribe(() => this.page.loadData() );
		this.selectedToLocations.subscribe(() => this.page.loadData() );
		this.selectedMovementTypes.subscribe(() => this.page.loadData() );
		this.selectedMovementSubtypes.subscribe(() => this.page.loadData() );

		this.confirmable_locations = [];
	}

	// EVENT: Download
	async download_movements_click ()
	{ 
		let options = this.currentOptions();

		options.limit = 100000;
		options.offset = 0;

		let filename = 'Stock Movement';

		let filter_values = {
			'Selected Source(s)': this.selectedFromLocations().length > 0 ? this.selectedFromLocations().map(item => item.name) : 'None',
			'Selected Destination(s)': this.selectedToLocations().length > 0 ? this.selectedToLocations().map(item => item.name) : 'None',
			'Selected Movement(s)': this.selectedMovementTypes().length > 0 ? this.selectedMovementTypes() : 'None',
			'Selected Movement Subtype(s)': this.selectedMovementSubtypes().length > 0 ? this.selectedMovementSubtypes() : 'None',
			'Selected Stock Item(s)': this.selectedStockItems().length > 0 ? this.selectedStockItems().map(item => item.description) : 'None',
			'Start Date': this.start_date() === undefined ? 'None' : this.start_date(),
			'End Date': this.end_date() === undefined ? 'None' : this.end_date(),
			'Show Suppliers': this.show_suppliers() ? 'Yes' : 'No'
		};

		let params = { 
			options: options, 
			headers: [
				{ field: 'order_nr', title: 'Order Number' },
				{ field: 'stock_take_date', title: 'Stock Take Date' },
				{ field: 'stock_item', title: 'Stock Item' },
				{ field: 'location', title: 'Location' },
				{ field: 'date_effective', title: 'Date Effective' },
				{ field: 'receive', title: 'Receive' },
				{ field: 'committed', title: 'Status' },
				{ field: 'movement_type', title: 'Movement Type' },
				{ field: 'movement_subtype', title: 'Movement Subtype' },
				{ field: 'qty', title: 'Qty' },
				{ field: 'note', title: 'Note' }
			],
			filters: filter_values
		};

		let url = await Grape.fetches.buildURL(`download/record/${filename}/`, params);
		
		window.open(url.href);
	}

	save_filter_click ()
	{
		if (this.current_filter_id())
			this.page.upsert_user_filter('PATCH', this.current_filter_id());
		else 
			this.page.upsert_user_filter('POST');

		this.page.loadfilters();
	}

	delete_filter_click ()
	{
		this.page.upsert_user_filter('DELETE', this.current_filter_id());

		this.clear_filters();

		this.page.loadfilters();
		this.page.loadData();
	}

	user_filter_selected (data)
	{
		this.current_filter_name(data.filter_name);
		this.current_filter_public(data.public);
		this.current_filter_id(data.user_filter_id);
		this.current_filter_criteria(data.filter_criteria);

		localStorage.setItem('last_used', this.current_filter_name());
		this.page.loadfilters();
	}

	clear_filter_click ()
	{
		this.clear_filters()

		Grape.alerts.alert({ type: 'success', title: 'Success', message: 'filter cleared' });
		this.page.is_loadData = false;
		this.page.loadData();
	}

	clear_filters ()
	{
		this.current_filter_name('');
		this.current_filter_public('');
		this.current_filter_id(null);
		this.current_filter_criteria([]);

		this.selectedFromLocations([]);
		this.selectedToLocations([]);
		this.selectedMovementTypes([]);
		this.selectedMovementSubtypes([]);
		this.selectedStockItems([]);
		this.start_date('');
		this.end_date('');
		localStorage.setItem('last_used', '');
	}

	createmovement_click () 
	{
		Grape.navigate('/movement/transaction/add');
	}

	async show_suppliers_click (show)
	{
		this.show_suppliers(show);

		let tempLocArray = [];

		//remove Supplier locations if selected when suppliers should be hidden
		if (this.selectedFromLocations().find(loc => loc.location_type === 'Supplier') && !show)
		{
			tempLocArray = this.selectedFromLocations().filter(loc => loc.location_type !== 'Supplier')
			this.selectedFromLocations(tempLocArray);
		}

		if (this.selectedFromLocations().find(loc => loc.location_type === 'Supplier') && !show)
		{
			tempLocArray = this.selectedFromLocations().filter(loc => loc.location_type !== 'Supplier')
			this.selectedFromLocations(tempLocArray);
		}

		let locations = await this.page.get_locations();

		if (locations)
			this.locations(locations);

		this.page.loadData();
	}
}

class StockMovementPage 
{
	constructor(bindings)
	{
		document.title = 'Stock Movements';

		this.is_loadData = false;
		this.viewModel = new StockMovementViewModel(this);
		this.bindings = bindings;

		this.viewModel.summary_filter(this.bindings.summary_filter);
	}

	async init() 
	{
		this.viewModel.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmStockMovement');

		try
		{
			// FILTER: Stock Groups
			await this.set_filter_stock_groups();

			let locations = await this.get_locations();
			this.viewModel.locations(locations);

			let stock_items = await Grape.cache.fetch('ActiveStockItems');
			this.viewModel.stock_items(stock_items);

			if (this.viewModel.summary_filter())
				this.load_summary_filter();
			else
			{
				let retrievedFilter = localStorage.getItem('last_used');

				this.viewModel.current_filter_name(retrievedFilter);
				await this.loadfilters();
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async loadData()
	{
		if (this.is_loadData)
			return;

		this.is_loadData = true;

		// Filter Structure
		let options =
		{
			table: 'v_detailed_stock_movement',
			schema: 'stock',
			sortfield: 'date_effective',
			sortorder: 'DESC',
			filter_join: 'AND',
			filter: []
		};

		let filter_base = [];
		// Add the date filter conditions to options.filter
		const startDate = this.viewModel.start_date();
		const endDate = this.viewModel.end_date();

		if (!this.viewModel.show_suppliers())
			filter_base.push({
				field: 'location_type', 
				operand: '=', 
				value: 'Internal'
			});

		if (startDate)
			filter_base.push({
				field: 'date_effective', 
				operand: '>=', 
				value: startDate
			});

		if (endDate)
		{
			let d = new Date(endDate);
			let transformed = moment(new Date(d.setDate(d.getDate() + 1))).format('YYYY-MM-DD');
			filter_base.push({
				field: 'date_effective', 
				operand: '<', 
				value: transformed
			});
		}

		// Create filter array for each input
		let fromLocationFilter = [];
		let toLocationFilter = [];
		
		// Data to push to filter array for each input
		if (this.viewModel.selectedFromLocations().length > 0) 
		{
			fromLocationFilter.push({
				field: 'location_id',
				operand: 'IN',
				value: this.viewModel.selectedFromLocations().map(loc => loc.location_id)
			});
			fromLocationFilter.push({
				field: 'qty',
				operand: '<',
				value: 0
			});
		}

		if (this.viewModel.selectedToLocations().length > 0) 
		{
			toLocationFilter.push({
				field: 'location_id',
				operand: 'IN',
				value: this.viewModel.selectedToLocations().map(loc => loc.location_id)
			});
			toLocationFilter.push({
				field: 'qty',
				operand: '>',
				value: 0
			});
		}

		if (this.viewModel.selectedMovementTypes().length > 0)
			filter_base.push({
				field: 'movement_type',
				operand: 'IN',
				value: this.viewModel.selectedMovementTypes()
			});

		if (this.viewModel.selectedMovementSubtypes().length > 0)
			filter_base.push({
				field: 'movement_subtype',
				operand: 'IN',
				value: this.viewModel.selectedMovementSubtypes()
			});

		if (this.viewModel.selectedStockItems().length > 0)
			filter_base.push({
				field: 'stock_item_id',
				operand: 'IN',
				value: this.viewModel.selectedStockItems().map(item => item.stock_item_id)
			});

		if (fromLocationFilter.length == 0 && toLocationFilter.length == 0)
			options.filter = filter_base;
		else if (fromLocationFilter.length != 0 && toLocationFilter.length == 0)
			options.filter = filter_base.concat(fromLocationFilter);
		else if (fromLocationFilter.length == 0 && toLocationFilter.length != 0)
			options.filter = filter_base.concat(toLocationFilter);
		else if (fromLocationFilter.length != 0 && toLocationFilter.length != 0)
		{
			options.filter = [
				{ 
					join: 'AND', 
					filter: filter_base.concat(fromLocationFilter) 
				},
				{ 
					join: 'AND', 
					filter: filter_base.concat(toLocationFilter) 
				}
			]
			options.filter_join = 'OR';
		}

		//filter for file download
		this.viewModel.currentOptions(options);
		this.is_loadData = false;
	}

	async get_locations ()
	{
		try 
		{
			let options = {
				table: 'v_locations',
				limit: 10000,
				schema: 'stock',
				sortfield: 'location_id',
				filter: [
					{
						field: 'in_use',
						operand: '=',
						value: true
					}
				]
			};

			if (!this.viewModel.show_suppliers())
				options.filter.push({
					field: 'location_type',
					operand: '=',
					value: 'Internal'
				});

			let result = await Grape.fetches.getJSON('/api/record', options);
			
			if (result.status != 'ERROR')
				return result.records;
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_summary_filter ()
	{
		let filter = this.viewModel.summary_filter();

		if (typeof(filter.movement_types) === 'string')
			this.viewModel.selectedMovementTypes.push(filter.movement_types || []);
		else if (typeof(filter.movement_types) === 'object')
			this.viewModel.selectedMovementTypes(filter.movement_types);

		if (Array.isArray(filter.from_locations))
			for (let loc of filter.from_locations)
				this.viewModel.selectedFromLocations.push(this.viewModel.locations().find(location => location.location_id === loc));
		else if (filter.from_locations)
			this.viewModel.selectedFromLocations.push(filter.from_locations || []);

		if (Array.isArray(filter.to_locations))
			for (let loc of filter.to_locations)
				this.viewModel.selectedToLocations.push(this.viewModel.locations().find(location => location.location_id === loc));
		else if (filter.to_locations)
			this.viewModel.selectedToLocations.push(filter.to_locations || []);

		this.viewModel.start_date(filter.start_date || null);
		this.viewModel.end_date(filter.end_date || null);
		if (filter.movement_subtypes)
			this.viewModel.selectedMovementSubtypes.push(filter.movement_subtypes || []);
		this.viewModel.selectedStockItems.push(filter.stock_items || []);
		
		this.is_loadData = false;
		await this.loadData();
	}

	async set_filter_stock_groups () 
	{
		try
		{
			// SERVER: Get Movement Sub Type
			let subtypeResult = await Grape.fetches.getJSON('/api/record', {
				table: 'movement_subtype',
				schema: 'stock'
			}); 

			if (subtypeResult.status == 'OK')
			{
				const filter = [...new Set(subtypeResult.records.map(item => item.movement_subtype))];
				this.viewModel.movement_subtypes(filter);
			}
			
			// SERVER: Get Movement Types
			let movementResult = await Grape.fetches.getJSON('/api/record', {
				table: 'movement_type',
				schema: 'stock'
			}); 

			if (movementResult.status != 'ERROR')
				this.viewModel.movement_types(movementResult.records.map(movement => movement.movement_type));
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async upsert_user_filter (method, user_filter_id)
	{
		if (this.viewModel.current_filter_public() == '')
			this.viewModel.current_filter_public(false);

		let filter_criteria = {
			movement_types: this.viewModel.selectedMovementTypes(),
			movement_subtypes: this.viewModel.selectedMovementSubtypes(),
			start_date: this.viewModel.start_date(),
			end_date: this.viewModel.end_date(),
			stock_items: this.viewModel.selectedStockItems()
		};

		if (this.viewModel.selectedFromLocations())
			filter_criteria.from_locations = this.viewModel.selectedFromLocations();

		if (this.viewModel.selectedToLocations())
			filter_criteria.to_locations = this.viewModel.selectedToLocations();

		let bodyObj = {
			table: 'user_filter',
			schema: 'stock',
			returning: '*'
		};

		if (method != 'DELETE')
			bodyObj.values = {
				user_uuid: Grape.currentSession.user.uuid,
				public: this.viewModel.current_filter_public(),
				filter_type: 'Movements',
				filter_name:  this.viewModel.current_filter_name(),
				filter_criteria: filter_criteria
			}

		if ((method === 'PATCH' || method === 'DELETE') && user_filter_id != null)
			bodyObj.filter = [{ 
				field: 'user_filter_id',
				operand: '=',
				value: user_filter_id
			}];
		
		try
		{
			if (!(method === 'POST' || method === 'PATCH' || method === 'DELETE'))
				throw new Error('Incorrect method passed');

			let response = await fetch('/api/record/', {
				method: method,
				body: JSON.stringify(bodyObj),
				headers: { 'content-type': 'application/json' }
			});

			let data = await response.json();

			if (response.ok)
			{
				this.viewModel.current_filter_criteria(data.filter_criteria);
				if (method === 'POST')
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter saved' });
				else if (method === 'DELETE')
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter deleted' });
				else 
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter updated' });
			}
			else
				throw new Error(data.message || result.code);
		} catch (err) {
			console.error(err);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: err.message });
		} finally {
			localStorage.setItem('last_used', this.viewModel.current_filter_name());
		}
	}

	async loadfilters ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', {
				table: 'v_user_filter',
				schema: 'stock',
				filter: [{
					field: 'filter_type',
					operand: '=',
					value: 'Movements'
				}]
			}); 

			if (result.status == 'OK')
			{
				if (result.records.length > 0)
				{
					for (let res of result.records)
						if (this.viewModel.current_filter_name() == res.filter_name)
						{
							this.viewModel.current_filter_criteria(res.filter_criteria);
							this.viewModel.current_filter_public(res.public);
							this.viewModel.current_filter_id(res.user_filter_id);

							this.viewModel.start_date(res.filter_criteria.start_date || null);
							this.viewModel.end_date(res.filter_criteria.end_date || null);
							this.viewModel.selectedMovementTypes(res.filter_criteria.movement_types || []);
							this.viewModel.selectedMovementSubtypes(res.filter_criteria.movement_subtypes || []);
							this.viewModel.selectedFromLocations(res.filter_criteria.from_locations || []);
							this.viewModel.selectedToLocations(res.filter_criteria.to_locations || []);
							this.viewModel.selectedStockItems(res.filter_criteria.stock_items || []);
						}

					this.viewModel.filters_array(result.records);
				}
				else 
					this.viewModel.current_filter_name('');

				this.is_loadData = false;
				this.loadData();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} 
	}
}

export default {
	route: '/stock/movement/list',
	page_class: StockMovementPage,
	template: template
};