import template from './location_types.html';

class LocationTypesPageViewModel 
{
	constructor (page) 
	{
		this.page = page;
		this.location_type_fields = ko.observableArray([]);
		this.location_types = ko.observableArray([]);
	}

	btn_edit_field_click (field)
	{
		field.is_editing(true);
	}

	async btn_delete_field_click (field)
	{
		let answer = await Grape.alerts.confirm({ type: 'danger', title: 'Confirm', message: 'Are you sure you want to delete this field' });

		if (!answer)
			return;

		try 
		{
			let response = await fetch('/api/stock-management/location-type-field/delete', {
				method: 'DELETE',
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify(
					{
						location_field_lookup_id: field.location_field_lookup_id
					}
				)
			});

			let data = await response.json();
	
            if (data.status === 'OK')
				await this.page.updateData();
			else
				throw new Error(data.message);	
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: `Error saving field: ${error}` });
			console.error('Error deleting field:', error);
		}
	}

	btn_add_field_click ()
	{
		let new_field = {
			location_field_lookup_id: null,
			location_type_id: null,
			location_type_value: '',
			location_type_description: '',
			location_field_name: '',
			is_new: true,
			is_editing: ko.observable(true)
		};
		this.location_type_fields.push(new_field);
	}

	async btn_save_field_click (field)
	{
		if (field.is_new) 
		{
			await this.upsert_field(field);
			delete field.is_new;
		}
		else
			await this.upsert_field(field);

		field.is_editing(false);
		await this.page.updateData();
	}

	async upsert_field (field)
	{
		try {
            let field_data = {
				location_field_lookup_id: field.location_field_lookup_id,
                name: field.location_field_name,
                location_type: field.location_type_id
            };

            let response = await Grape.fetches.postJSON('/api/stock-management/location-type-field/upsert', field_data);

            if (response.status === 'OK')
                await this.page.updateData();
            else
                throw new Error(response.message)
        } catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: `Error saving field: ${error}` });
            console.error('Error saving field:', error);
        }
	}
}

class LocationTypesPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new LocationTypesPageViewModel(this);
		document.title = 'SystemSetup: Location Types';
	}

	async init ()
	{
		await this.updateData();
	}

	async updateData ()
	{
		let result = await Grape.fetches.getJSON('/api/record', 
		{
			schema: 'stock',
			table: 'v_location_types',
			sortorder: 'ASC',
			sortfield: 'location_field_lookup_id'
		});

		if (result.status != 'ERROR')
		{
			// Add is_editing to all fields
			let fields = result.records.map(record => ({
			  ...record,
			  is_editing: ko.observable(false)
			}));
			this.viewModel.location_type_fields(fields);

			// Get unique types for the type selector
			let unique_location_types = Object.values(result.records.reduce((acc, record) => {
				if (record.location_type_value && record.location_type_id)
				{
					acc[record.location_type_value] = {
						location_type_id: record.location_type_id,
						location_type_value: record.location_type_value
					};
				}
				return acc;
			}, {}));

			this.viewModel.location_types(unique_location_types);
		}
		else
			throw new Error(result.message || result.code);
	}
}

export default {                
	route: '[/]stock/admin/location-types',
	page_class: LocationTypesPage,
	template: template              
}