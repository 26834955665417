import template from './StockItemEdit.html';

class ProductViewModel 
{
	constructor (page) 
	{ 
		this.initialized = false;

		this.page = page;

		this.stock_info = ko.observableArray([]);
		this.idx = ko.observable();
		this.description = ko.observable();
		this.code = ko.observable();
		this.stock_group_id = ko.observable(); //TODO: Update the front-end to mirror the back-end. In DB stock_tag is TEXT[]
		this.uom_id = ko.observable();
		this.uom_qty = ko.observable();
		this.uom_list = ko.observableArray([]);
		this.stock_groups = ko.observableArray([]);
		this.in_use = ko.observable(true);
		this.show_elaborate_prompt = ko.observable(true);
		this.new_tag = ko.observable();
		this.stock_tag = ko.observableArray();
		this.new_name = ko.observable();
		this.other_names = ko.observableArray();

		this.stock_item_id = ko.observable(this.page.bindings.stock_info ? this.page.bindings.stock_info.stock_item_id : null);
		this.type = ko.observable(this.page.bindings.type || 'stock_item');

		this.uom_id.subscribe((newVal) => {
			if (this.initialized)
				if (newVal === null)
					this.uom_qty('');
		});
	}

	//Event Handlers
	add_other_name (value, event) 
	{
		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (this.new_name())
		{
			if (!this.other_names())
				this.other_names([]);
			this.other_names.push(this.new_name());
			this.new_name("");
		}

		return true;
	}

	remove_other_name (name) 
	{
		this.other_names.splice(this.other_names.indexOf(name), 1);
	}

	add_stock_tag (value, event) 
	{
		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (this.new_tag())
		{
			if (!this.stock_tag())
				this.stock_tag([]);
			this.stock_tag.push(this.new_tag());
			this.new_tag("");
		}

		return true;
	}

	remove_stock_tag (tag) 
	{
		this.stock_tag.splice(this.stock_tag.indexOf(tag), 1);
	}
}

class ProductPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new ProductViewModel(this);

		this.viewModel.stock_info(bindings.stock_info || []);
	}
	
	async init ()
	{
		try
		{
			//SERVER: Get list of Stock Groups
			this.viewModel.stock_groups(await Grape.cache.fetch('StockGroups'));
			let uom_list = JSON.parse(JSON.stringify(await Grape.cache.fetch('UOM')));
			uom_list.unshift({name: '(None)', uom_id: null});
			this.viewModel.uom_list(uom_list);

			//LOGIC: Initialise fields if Stock Item exists
			if (this.viewModel.stock_item_id())
			{
				this.viewModel.show_elaborate_prompt(false);

				//SERVER: List Stock Items based on ID 
				let itemsResult= await Grape.fetches.getJSON('/api/record/',
				{
					table: 'stock_item', 
					schema: 'stock',
					filter: [ 
						{
							field: 'stock_item_id',
							value: this.viewModel.stock_item_id(),
							operand: '='
						}
					]
				}); 

				if (itemsResult.status == 'OK') 
				{
					if (itemsResult.records)
					{
						this.viewModel.description(itemsResult.records[0].description);
						this.viewModel.code(itemsResult.records[0].code);
						this.viewModel.stock_group_id(itemsResult.records[0].stock_group_id);
						this.viewModel.uom_id(itemsResult.records[0].uom_id);
						this.viewModel.uom_qty(itemsResult.records[0].uom_qty);
						this.viewModel.in_use(itemsResult.records[0].in_use);
						this.viewModel.stock_tag(itemsResult.records[0].stock_tag);
						this.viewModel.other_names(itemsResult.records[0].other_names);
						this.viewModel.idx(itemsResult.records[0].idx);
					}
					else
						throw new Error('Product not found');
				}
			}
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: 'Product not found' });
			console.error(error);
		}

		this.viewModel.initialized = true;
	}

	//EVENT: Save Product
	async saveProduct_click () 
	{
		//SERVER: Create or Update Product Details
		if (this.viewModel.description() == null || this.viewModel.description().trim() == '' )
			throw new Error('Please provide a description for stock item.')

		let uom_qty = null;
		if (this.viewModel.uom_id() !== null)
		{
			let has_qty = false;
			let uom_qty_str = '';
			if (this.viewModel.uom_qty())
			{
				has_qty = true;
				uom_qty_str = this.viewModel.uom_qty().toString().replace(/\s/g, '');
			}

			if (has_qty && uom_qty_str.length > 0 && !isNaN(uom_qty_str))
				uom_qty = uom_qty_str;

			if (!uom_qty)
			{
				Grape.alerts.alert({ type: 'error', title: 'error', message: 'Please provide a value for the UOM quantity.' });
				return;
			}
		}

		let obj = {
			in_use: this.viewModel.in_use(),
			idx: parseInt(this.viewModel.idx()),
			description: this.viewModel.description(),
			code: this.viewModel.code(),
			stock_item_id: this.viewModel.stock_item_id(),
			stock_group_id: this.viewModel.stock_group_id(),
			uom_id: this.viewModel.uom_id(),
			uom_qty: uom_qty,
			other_names: this.viewModel.other_names(),
			stock_tag: this.viewModel.stock_tag()
		};

		this.close(obj);
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
	name: 'StockItemEdit',
	dialog_class: ProductPage,
	template: template
}