import template from './ko-associated-orders.html';

class AssociatedOrdersComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.order_id = ko_helper.safe_observable(this.params.order_id || '');
		this.order_type = ko_helper.safe_observable(this.params.for_type || '');
		this.reload = ko_helper.safe_observable(this.params.reload || false);
		
		this.loading = ko.observable(false);
		this.orders = ko.observableArray([]);
		this.creatable_locations = ko.observableArray([]);
		this.selected_type = ko.observable('')

		// Sort
		this.sortField = ko.observable('order_date');
		this.sortOrder = ko.observable('DESC');

		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([20, 40, 100]);
		this.selected_number_of_records = ko.observable(20);

		this.currentOptions = ko.observableArray();

		this.selected_assoc_tab = ko.observable('');

		this.selected_assoc_tab.subscribe((newValue) => {
			if (newValue === 'work_orders')
				this.selected_type('Work Order');
			else if (newValue === 'production_orders')
				this.selected_type('Production Order');
			else if (newValue === 'production_picking_orders')
				this.selected_type('Production Picking Order');
			else if (newValue === 'sales_orders')
				this.selected_type('Sales Order');
		});

		if (this.order_type() === 'Production Order')
			this.selected_assoc_tab('work_orders');
		else if (this.order_type() === 'Work Order')
			this.selected_assoc_tab('production_orders');

		this.reload.subscribe((newValue) => {
			if (newValue)
				this.update_data();
		});
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#prod-orders-create-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_assoc_tab(event.currentTarget.getAttribute('data-tabname'));
		this.update_data();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.update_data();
	}

	page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.update_data();
	}

	btn_view_order_click (order) 
	{
		if (this.selected_assoc_tab() === 'work_orders')
			Grape.navigate(`/work/order/view/${order.order_id}`);
		else if (this.selected_assoc_tab() === 'production_orders')
			Grape.navigate(`/production/order/view/${order.order_id}`);
		else if (this.selected_assoc_tab() === 'production_picking_orders')
			Grape.alerts.alert({ type: 'info', title: '', message: 'This feature is in development!' });
		else if (this.selected_assoc_tab() === 'sales_orders')
			Grape.alerts.alert({ type: 'info', title: '', message: 'This feature is in development!' });
		else
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error! Order not found!' });
	}

	btn_edit_order_click (order) 
	{
		if (this.selected_assoc_tab() === 'work_orders')
			if (this.can_capture_order(order))
				Grape.navigate(`/work/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/work/order/view/${order.order_id}`);
		else if (this.selected_assoc_tab() === 'production_orders')
			if (this.can_capture_order(order))
				Grape.navigate(`/production/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/production/order/view/${order.order_id}`);
		else if (this.selected_assoc_tab() === 'production_picking_orders')
			if (this.can_capture_order(order))
				Grape.alerts.alert({ type: 'info', title: '', message: 'This feature is in development!' });
			else
				Grape.alerts.alert({ type: 'info', title: '', message: 'This feature is in development!' });
		else if (this.selected_assoc_tab() === 'sales_orders')
			Grape.alerts.alert({ type: 'info', title: '', message: 'This feature is in development!' });
		else
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error! Order not found!' });
	}

	can_capture_order (order) 
	{
		let allow = false;

		if (order.source_location && order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (
					(this.creatable_locations.find(loc => loc.location_name == order.source_location))
					&& (this.creatable_locations.find(loc => loc.location_name == order.target_location))
			))
				allow = true;
		}
		else if (order.source_location)
		{
			if ( Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.creatable_locations.find(loc => loc.location_name == order.source_location)))
				allow = true;
		}
		else if (order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.creatable_locations.find(loc => loc.location_name == order.target_location)))
				allow = true;
		}

		return allow;
	}

	async update_data ()
	{
		this.loading(true);

		this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

		let options = {
			table: 'v_orders',
			schema: 'stock',
			offset: 0,
			sortorder: this.sortOrder(),
			sortfield: this.sortField(),
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'order_type',
				operand: '=',
				value: this.selected_type(),
			}]
		}

		if (this.order_id() != '' && this.order_type() === 'Production Order')
		{
			options.filter.push({
				field: 'parent_order_id',
				operand: '=',
				value: this.order_id(),
			});
		}
		else if (this.order_id() != '' && this.order_type() === 'Work Order')
		{
			options.filter.push({
				field: 'child_order_ids',
				operand: '@>',
				value: [this.order_id()],
			});
		}

		this.currentOptions(options);

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}
 
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				result.records.forEach(order => {
					let order_nr_txt = order.order_nr;
					if (order.parent_order_id)
						order_nr_txt += ` (${order.parent_order_nr})`;

					order.order_nr_txt = order_nr_txt;
				});

				this.orders(result.records);
				this.page_count(Math.ceil(result.total/result.limit));
				this.pagination_summary(`Showing ${options.offset} - ${this.current_page_number() * this.current_page_size()} of ${result.total} order(s)`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
		this.loading(false);
	}
}

export default {
	name: 'ko-associated-orders',
	viewModel: AssociatedOrdersComponentViewModel,
	module_type: 'ko',
	template: template
}