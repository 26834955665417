import template from './l_files.html';

class LocationFilesPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.location_id = ko.observable();
		this.files_location = ko.observable('');
		this.location_files = ko.observableArray([]);
	}

	async btn_upload_doc_click ()
	{
		let file_input = document.getElementById('ps-file-upload-input');
		let files = file_input.files;

		if (files.length > 0)
		{
			for (let file of files)
			{
				let response = await fetch(`${this.files_location()}/${file.name}`, {
					method: 'PUT',
					headers: { 'content-type': file.type },
					body: file
				});

				if (!response.ok)
					Grape.alerts.alert({ type: 'warning', title:'Warning', message: `"File upload failed for", ${file.name}` });
			}

			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Document(s) successfully uploaded!' });
			this.page.updateData();
		} 
		else
			Grape.alerts.alert({ type: 'info', title: 'No File', message: 'No files selected!' });
	}

	async btn_delete_file_click (file) 
	{
		let response = await Grape.alerts.confirm({
			message: 'Are you sure you want to delete this file?',
			title: 'Delete File',
			type: 'danger'
		})

		if (response)
		{
			let response = await fetch(file.full_path, {
				method: 'DELETE',
				headers: { 'content-type': 'application/json' },
				body: []
			});

			if (response.ok)
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Document successfully deleted!' });
				this.page.updateData();
			}
			else
				console.error('File deletion failed', response.status);
		}
	}

	async btn_save_description_click (row)
	{
		let description = row.description();
		let full_path = row.full_path;
	
		let body = `
			<d:propertyupdate xmlns:d='DAV:'>
				<d:set>
					<d:prop>
						<o:description>${description}</o:description>
					</d:prop>
				</d:set>
			</d:propertyupdate>
		`;
	
		let response = await fetch(full_path, {
			method: 'PROPPATCH',
			headers: {
				'Content-Type': 'application/xml'
			},
			body: body
		});
	
		if (response.ok) 
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Description successfully updated!' });
		else
		{
			Grape.alerts.alert({ type: 'warning', title:'Warning', message: 'Failed to update description.' });
			console.error('PROPPATCH failed', response.status);
		}

		row.is_editing_row(false);
	}
}

class LocationFilesPage
{
	constructor (bindings)
	{
		this.viewModel = new LocationFilesPageVM(this);
		this.bindings = bindings;
		this.viewModel.location_id(bindings.location_id);
		this.timer = null;
		this.loading = ko.observable(false);

		this.filter = [{
			field: 'location_id', 
			value: this.viewModel.location_id(), 
			operand: '='
		}];
	}

	async init ()
	{
		document.title = 'Item Files';
	}

	async updateData ()
	{
		this.loading(true);
		this.viewModel.location_files([]);

		// Stock Item Info
		let location_result = await Grape.fetches.getJSON('/api/record', 
		{
			table: 'v_locations',
			schema: 'stock',
			sortorder: 'DESC',
			fields: ['name', 'location_type', 'location_id'],
			filter: this.filter
		});

		if (location_result.status == 'OK')
			this.viewModel.files_location(`StockFiles/Locations/${location_result.records[0].location_id}`);

		// Stock Item Files
		let file_result = await Grape.fetches.fetchJSON(this.viewModel.files_location(),
		{
			headers: {'content-type': 'application/json'}, 
			method: 'PROPFIND', 
			body: JSON.stringify([])
		});
		file_result.shift();

		for (let file of file_result)
		{
			let file_info = {
				full_path: file.full_path,
				file_name: '',
				user: '',
				date: '',
				description: ko.observable(''),
				is_editing_row: ko.observable(false)
			};

			for (let prop of file.properties)
				if (prop.name === 'displayname')
					file_info.file_name = prop.value;
				else if (prop.name === 'creator-displayname')
					file_info.user = prop.value;
				else if (prop.name === 'creationdate')
					file_info.date = prop.value;
				else if (prop.name === 'description')
					file_info.description(prop.value);

			this.viewModel.location_files.push(file_info);
		}
		this.loading(false);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/location/]l_files',
	page_id: 'si_files',
	page_class: LocationFilesPage,
	template: template
};