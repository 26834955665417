import template from './edit_user_locations.html';

class EditUserLocationsViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.locations = ko.observableArray();

		this.permissions = ko.observableArray([ 'CreatePurchaseOrder' , 'CreateDeliveryNote' , 'CreateStockTake', 'ViewStockLevel', 'CreateStockMovement', 'CreateSupplierReturn' ]);
		this.permissionsHeader = ko.observableArray([ 'Location', 'Create Purchase Order' , 'Create Delivery Note' , 'Create Stock Take', 'View Stock level', 'Create Stock Movement', 'Create Supplier Return', 'Select All' ]);
	}

	check_all_clicked (data) 
	{
		let index = this.locations.indexOf(data);
		let permissions = this.permissions();
		let locations = this.locations();

		if (data.permissions.length > 0)
			locations[index].permissions = [];
		else
			locations[index].permissions = permissions;

		this.locations(locations);

		this.dialog.save_locations();
		this.dialog.updateData();
		
		return true;
	}
	
	check_entire_column (column)
	{
		let locations= this.locations();
		let found_count = 0;

		for (let row of locations)
		{
			let index = locations.indexOf(row);

			if (row.permissions.indexOf(column) == -1)
				locations[index].permissions.push(column);
			else 
				found_count++;
		}

		if (found_count == locations.length)
		{
			for (let row of locations)
			{
				let index = locations.indexOf(row);
				let item_index = locations[index].permissions.indexOf(column);
				locations[index].permissions.splice(item_index, 1);
			}
		}

		this.locations(locations);
		this.dialog.save_locations();
		this.dialog.updateData();
	}

	check_all ()
	{
		let locations= this.locations();
		let permission_count = 0;
		let max_permission_count = this.locations().length * this.permissions().length;

		for (let row of locations)
		{
			let index = locations.indexOf(row);

			if (locations[index].permissions.length == this.permissions().length)
				permission_count += locations[index].permissions.length;

			locations[index].permissions = [];
			locations[index].permissions = this.permissions();
		}

		if (max_permission_count == permission_count)
			for (let i =0; i< locations.length; i++)
				locations[i].permissions = [];

		this.locations(locations);
		this.dialog.save_locations();
		this.dialog.updateData();
	}

	updateRow (data)
	{
		let index = this.locations.indexOf(data);
		let locations = this.locations();

		this.dialog.save_locations();
		this.dialog.updateData();

		return true;
	}
}

class EditUserLocationsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditUserLocationsViewModel(this);
		this.user_uuid = bindings.user_uuid;
		this.locations = []
		this.user_locations = [];

		this.init();
	}

	init ()
	{
		this.updateData();
	}

	async updateData ()
	{
		try
		{
			let location_result = await Grape.fetches.getJSON('/api/record', {
				table: 'location', 
				schema: 'stock', 
				sortfield: 'description', 
				filter: [{
					field: 'in_use', 
					operand: '=', 
					value: 'true'
				}]
			});

			this.locations = location_result.records;

			let user_location_results = await Grape.fetches.getJSON('/user_location', {user_uuid: this.user_uuid});
			this.user_locations = user_location_results.locations;

			for (let i = 0; i < this.locations.length; i++)
			{
				this.locations[i].permissions = this.get_permissions(this.locations[i].location_id);
			}

			this.viewModel.locations(this.locations);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'warning', message: error.message, title: 'Error' });
		}
	}

	get_permissions(location_id)
	{
		let permissions = [];
		for (let i = 0; i < this.user_locations.length; i++)
			if (this.user_locations[i].location_id == location_id)
				permissions = this.user_locations[i].permissions;

		return permissions;
	}

	save_locations_click()
	{
		this.save_locations()
		this.viewModel.dialog.close();
	}

	async save_locations()
	{
		let locations = this.viewModel.locations();
		let new_locations = [];

		for (let i = 0; i < locations.length; i++)
			if (locations[i].permissions.length > 0)
				new_locations.push(locations[i]);

		try
		{
			let results = await Grape.fetches.postJSON('/user_location', {
				user_uuid: this.user_uuid, 
				locations: new_locations
			});

			if (results.status == 'OK')
				Grape.cache.refresh('UserLocations');
			else
				throw new Error(results);

		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'warning', message: error.message, title: 'Error' });
		}
	}

	btn_cancel_click ()
	{
		this.close();
	}
}

export default {
	name: 'EditUserLocations',
	dialog_class: EditUserLocationsPage,
	template: template
}
