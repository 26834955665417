import template from './order_confirm.html';

class OrderConfirmViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.order = ko.observableArray([]);
	}

	btn_back_click ()
	{
		this.dialog.close();
	}

	save_order_click ()
	{
		this.dialog.close(true);
	}
}

class OrderConfirmDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new OrderConfirmViewModel(this);

		this.viewModel.order(this.bindings.order);
	}
}

export default {
	name: 'OrderConfirm',
	dialog_class: OrderConfirmDialog,
	template: template
}
