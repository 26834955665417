import template from './WorkstationScheduleOverlap.html';

class WorkstationScheduleOverlapViewModel 
{
	constructor (dialog) 
	{ 
		this.dialog = dialog;
		this.day = ko.observable('');
		this.items = ko.observable('');
	}
}

class WorkstationScheduleOverlapDialog
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new WorkstationScheduleOverlapViewModel(this);
	}
	
	init ()
	{
		this.viewModel.day(this.bindings.day);
		this.viewModel.items(this.bindings.items);
	}

	btn_cancel_click ()
	{
		this.close(false);
	}

	btn_confirm_click ()
	{
		this.close(true);
	}
}

export default {
	name: 'WorkstationScheduleOverlap',
	dialog_class: WorkstationScheduleOverlapDialog,
	template: template
}