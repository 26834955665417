import template from './ko-production-order-items.html';

class ProductionOrderItemsComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.is_initial_load = ko.observable(true);
		this.selected_assoc_tab = ko.observable('bom_items');
		this.available_items_object = ko.observableArray([]);
		this.order_items = ko_helper.safe_observableArray(params.order_items || []);
		this.selected_items = ko_helper.safe_observableArray(params.initial_selected_items);
		this.type = ko_helper.safe_observable(params.type);
		this.delivery_date = ko_helper.safe_observable(params.delivery_date);
		this.can_send_delivery = ko_helper.safe_observable(params.can_send_delivery || false);
		this.can_receive_delivery = ko_helper.safe_observable(params.can_receive_delivery || false);
		this.components_table_records = ko_helper.safe_observable(params.components_table_records || []);
		this.sent_outstanding_html = ko.observable('<span>Outstanding</span>');
		this.receive_outstanding_html = ko.observable('<span>Outstanding</span>');
		this.available_locations = ko.observableArray([]);
		this.available_location_ids = ko.observableArray([]);

		this.can_send_delivery.subscribe(() => this.update_outstanding_column_headings() );
		this.can_receive_delivery.subscribe(() => this.update_outstanding_column_headings() );

		this.selected_items.subscribe(async (new_selected_items) => {

			for (let selected_item of new_selected_items)
			{
				let has_product = this.order_items().some((item) => item.bom_id === selected_item.bom_id);
				let has_different_version = this.order_items().some((item) => item.stock_item_id === selected_item.stock_item_id && item.bom_id != selected_item.bom_id);
				let item = this.available_items_object().find((item) => item.bom_id == selected_item.bom_id);

				if (item && !has_product && !has_different_version)
				{
					let new_order_item = {
						stock_item_id: item.stock_item_id,
						code: item.code,
						description: item.description,
						bom_id: selected_item.bom_id,
						qty: ko.observable(0),
						expected_receive_dates: ko.observableArray([{ date: ko.observable(this.delivery_date()), qty: ko.observable(0) }])
					};
					this.order_items.push(new_order_item);
					this.get_new_order_item_components(new_order_item);
				}
				else if (item && !has_product && has_different_version)
				{
					let result = await Grape.alerts.confirm({
						type: 'warning',
						message: 'An order item for the same BOM with a different version already exists. Do you want to replace it? This will remove the qty for the BOM item!',
						title: 'Duplicate BOM Item'
					});
					if (result)
					{
						this.order_items.remove((existing_item) => existing_item.stock_item_id === selected_item.stock_item_id && existing_item.bom_id != selected_item.bom_id);

						let new_order_item = {
							stock_item_id: item.stock_item_id,
							code: item.code,
							description: item.description,
							bom_id: selected_item.bom_id,
							qty: ko.observable(0),
							expected_receive_dates: ko.observableArray([{ date: ko.observable(this.delivery_date()), qty: ko.observable(0) }])
						};
						this.order_items.push(new_order_item);	
						this.get_new_order_item_components(new_order_item);
					}
					else
						this.selected_items.remove((selected_item) => item.bom_id === selected_item.bom_id)
				}
				else if (item && has_product)
				{
					this.order_items().map(order_item => {
						if (order_item.bom_id === item.bom_id)
							order_item.bom_id = item.bom_id;
						return order_item;
					});
				}
				else if (!item && has_product)
				{
					this.order_items().filter(order_item => {
						return new_selected_items.some(selected_item => selected_item.bom_id === order_item.bom_id);
					});
				}
			}

			if (this.is_initial_load())
			{
				this.get_existing_order_item_components();
				this.is_initial_load(false);
			}
		});

		this.delivery_date.subscribe((new_date) => {
			this.order_items().forEach((item) => {
				item.expected_receive_dates().forEach((val) => val.date(new_date) );
			});
		});

		this.available_items_changed = this.available_items_changed.bind(this);
		this.get_all_locations();
	}

	available_items_changed (newItems)
	{
		this.available_items_object(newItems);
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#prod-order-items-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_assoc_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	column_visibility ()
	{
		if (this.type() == 'create')
			return false;
		else
			return true;
	}

	view_mode () 
	{
		return this.type() === 'view';
	}

	create_mode () 
	{
		return this.type() === 'create';
	}

	async btn_remove_item_click (item) 
	{
		let result = await Grape.alerts.confirm(
		{
			type: 'warning',
			message: 'Are you sure you want to remove this order item?',
			title: 'Remove Order Item', 
		});

		if (result)
		{
			this.order_items.remove(item);

			this.components_table_records.remove(function(component) {
				return component.bom_stock_item_id === item.stock_item_id;
			});
	
			let index = -1;
			let count = 0;
			let items = this.selected_items();
			items.forEach((s_item) => {
				if (s_item.bom_id === item.bom_id)
					index = count;
				count++;
			});
			if (index !== -1)
			{
				items.splice(index, 1);
				this.selected_items(items);
			}
		}
	}

	async btn_remove_delivery_date_click (item, delivery)
	{
		// Check if this is the last delivery for the order item
		let single_delivery = item.expected_receive_dates().length === 1;
		let confirm_message = single_delivery ? 'Are you sure you want to remove this order item?' : 'Are you sure you want to remove this delivery?';
	
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: confirm_message,
			title: single_delivery ? 'Remove Order Item' : 'Remove Delivery',
		});
	
		if (result)
		{
			item.expected_receive_dates.remove(delivery);
			if (item.expected_receive_dates().length === 0) 
			{
				this.order_items.remove(item);

				let index = -1;
				let count = 0;
				let items = this.selected_items();
				items.forEach((s_item) => {
					if (s_item.bom_id == item.bom_id)
						index = count;
					count++;
				});
				items.splice(index, 1);
				this.selected_items(items);
			}
		}
	}

	btn_add_extra_delivery_click (item)
	{
		item.expected_receive_dates.push({date: ko.observable(this.delivery_date()), qty: ko.observable(0)});
	}

	update_outstanding_column_headings()
	{
		if (this.can_send_delivery() && this.can_receive_delivery())
		{
			this.sent_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Sent)</span>');
			this.receive_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Received)</span>');
		}
	}

	async get_all_locations ()
	{
		let location_type_ids = await Grape.fetches.getJSON('/api/record', {
			table: 'v_location_types',
			schema: 'stock',
			fields: ['location_type_id'],
			filter_join: 'OR',
			filter: [
				{ field: 'location_type_value', operand: '=', value: 'Internal' },
				{ field: 'location_type_value', operand: '=', value: 'Raw Materials' },
				{ field: 'location_type_value', operand: '=', value: 'Finished Goods' },
				{ field: 'location_type_value', operand: '=', value: 'Workstation' }
			]
		});

		let filter = [];

		for (let i = 0; i < location_type_ids.records.length; i++)
			filter.push({field: 'location_type', operand: '=', value: location_type_ids.records[i].location_type_id});

		let locations = await Grape.fetches.getJSON('/api/record', {
			table: 'location',
			schema: 'stock',
			filter_join: 'OR',
			filter: filter
		});

		this.available_locations(locations.records);
		let location_ids = locations.records.map((record) => record.location_id);

		if (location_ids.length > 0)
			this.available_location_ids(location_ids);
	}

	async get_existing_order_item_components ()
	{
		if (this.order_items().length === 0)
		{
			this.components_table_records([]);
			return;
		}
	
		let all_components = [];
	
		let production_order_id = this.params.order_id;
	
		if (this.params.order_id() !== '')
		{
			try {
				let components_data = await Grape.fetches.getJSON('/api/record', {
					table: 'v_production_order_components',
					schema: 'stock',
					filter: [
						{ field: 'production_order_id', operand: '=', value: production_order_id }
					]
				});
	
				let all_stock_item_ids = components_data.records.map(component => component.component_stock_item_id);
				let loc_arr = [];
				loc_arr.push(this.available_location_ids());
	
				let obj = {
					stock_item_ids: all_stock_item_ids,
					location_ids: loc_arr.flat()
				};
	
				let levels_response = await Grape.fetches.getJSON('/api/stock-management/stock-level', obj);
	
				let stock_levels_map = {};
	
				for (let record of levels_response.records)
				{
					let stock_item_id = record.stock_item_id;
					if (!stock_levels_map[stock_item_id])
						stock_levels_map[stock_item_id] = [];
	
					stock_levels_map[stock_item_id].push(record);
				}
	
				all_components = components_data.records.map(component => {
					let source_location = this.available_locations().find(
						loc => loc.location_id === component.component_source_location_id
					);
	
					let stock_levels = stock_levels_map[component.component_stock_item_id] || [];
	
					let total_qty = stock_levels.reduce((sum, level) => sum + level.qty, 0);
	
					let selected_source_qty = 0;
					if (source_location)
					{
						let level = stock_levels.find(level => level.location_id === source_location.location_id);
						if (level)
							selected_source_qty = level.qty;
					}
	
					let component_item = {
						bom_description: component.bom_description,
						sku: component.sku,
						description: component.component_description,
						group: component.stock_group,
						qty_required: component.qty_required,
						uom: component.uom_symbol,
						stock_item_id: component.component_stock_item_id,
						bom_stock_item_id: component.bom_stock_item_id,
						qty_used: component.qty_used,
						qty_outstanding: component.qty_outstanding,
						selected_source: ko.observable(source_location),
						selected_source_qty: ko.observable(selected_source_qty),
						total_qty: ko.observable(total_qty)
					};
	
					component_item.selected_source.subscribe((new_location) => {
						let new_qty = 0;
	
						if (new_location)
						{
							let level = stock_levels.find(level => level.location_id === new_location.location_id);
							if (level)
								new_qty = level.qty;
						}
						component_item.selected_source_qty(new_qty);
					});
	
					return component_item;
				});
	
				this.components_table_records(all_components);
	
			} catch (error) {
				console.error('Failed to fetch and populate BOM components data:', error);
			}
		}
	}

	async get_new_order_item_components (new_order_item)
	{
		let bom_components = await Grape.fetches.getJSON('/api/record', {
			table: 'v_bom_stock_items',
			schema: 'stock',
			fields: [
				'component_stock_item_id',
				'component_qty',
				'code',
				'stock_item',
				'stock_group',
				'uom_symbol',
				'bom_step_id'
			],
			filter: [
				{ field: 'bom_id', operand: '=', value: new_order_item.bom_id }
			]
		});

		for (let component of bom_components.records)
		{
			if (component.bom_step_id !== null)
			{
				let qty_required = component.component_qty * new_order_item.qty();

				let component_item = {
					bom_description: new_order_item.description,
					sku: component.code,
					description: component.stock_item,
					group: component.stock_group,
					qty_required: qty_required,
					uom: component.uom_symbol,
					stock_item_id: component.component_stock_item_id,
					bom_stock_item_id: new_order_item.stock_item_id,
					qty_used: 0,
					qty_outstanding: qty_required,
					selected_source: ko.observable(),
					selected_source_qty: ko.observable(0),
					total_qty: ko.observable(0)
				};

				this.components_table_records.push(component_item);
			}
		}
	}
}

export default {
	name: 'ko-production-order-items',
	viewModel: ProductionOrderItemsComponentViewModel,
	module_type: 'ko',
	template: template
}