import template from './location_view.html';

class LocationsViewModel
{
	constructor(page)
	{
		this.title = ko.observable('Location');
		this.page = page;
		this.location_id = ko.observable();
		this.location_description = ko.observable('Location View');
		this.selected_location = ko.observableArray([]);
		this.available_locations = ko.observableArray([]);
		this.location_type = ko.observable();
		this.tabs = ko.observableArray([
			{
				route: '[/location/]l_overview',
				title: 'Overview',
				icon: 'fa-solid fa-info-circle fa-lg'
			},
			{
				route: '[/location/]l_orders',
				title: 'Orders',
				icon: 'fa-solid fa-bags-shopping fa-lg'
			},
			{
				route: '[/location/]l_movements',
				title: 'Movements',
				icon: 'fa-solid fa-arrow-trend-up fa-fade fa-lg'
			},
			{
				route: '[/location/]l_users',
				title: 'Users',
				icon: 'fa-solid fa-users fa-lg'
			},
			{
				route: '[/location/]l_files',
				title: 'Files',
				icon: 'fa-solid fa-users fa-lg'
			},
			{
				route: '[/location/]l_stock_items',
				title: 'Stock Items',
				icon: 'fa-solid fa-file fa-lg'
			}
		]); 
		this.current_route = ko.observable();

		this.selected_location.subscribe((newLocation) => {
			if (newLocation[0] !== undefined)
			{
				Grape.navigate(`admin/location/view/${newLocation[0].location_id}`);
				localStorage.setItem('movement_summary_last_location', newLocation[0].location_id);
			}
		});
	}

	load_tab (route, location_id, location_description, location_type)
	{
		window.localStorage.setItem('last_location_sp', route);
		const element = document.querySelector('.location_page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);

		if (!page)
			console.error(`Page with id '${route}' not found`);

		Grape.pages.load_page(page, element, { 
			location_id: location_id, 
			location_description: location_description, 
			location_type: location_type 
		}, this.page, () => {});
	}
}

class LocationsPage
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new LocationsViewModel(this);
		this.viewModel.location_id(this.bindings.location_id);
	}
	
	async init ()
	{
		let result = await Grape.cache.fetch('Locations');

		for (let loc of result)
			if (loc.location_id == this.viewModel.location_id())
			{
				this.viewModel.location_description(loc.name);
				this.viewModel.location_type(loc.location_type);
				this.viewModel.selected_location(loc);
			}

		this.viewModel.available_locations(result);

		document.title = 'Location View';

		if (window.localStorage.getItem('last_location_sp'))
			this.viewModel.load_tab(window.localStorage.getItem('last_location_sp'), this.viewModel.location_id(), this.viewModel.location_description(), this.viewModel.location_type());
		else
			this.viewModel.load_tab('[/location/]l_overview', this.viewModel.location_id(), this.viewModel.location_description(), this.viewModel.location_type());
	}
}

export default {
	route: '[/]admin/location/view/:location_id',
	page_class: LocationsPage,
	template: template
}